// 首页轮播图
<template>
  <div class="block">
    <el-carousel trigger="click" :interval="4000" :style="{height:imgHeight+'px'}">
      <el-carousel-item :style="{height:imgHeight+'px'}" v-for="item in imagesbox" :key="item.id">
        <a >
          <!-- <img :style="{height:imgHeight+'px'}" ref="imgH" :src="item.url" alt=""> -->
          <img :style="{height:imgHeight+'px'}" ref="imgH" :src="item.url" alt="" @load="imgLoad">
        </a>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import {getContents} from "@/api/index.js";
export default {
  name: '',
  data() {
    return {
      imgHeight: window.innerHeight ,// 获取页面高度
      // imgHeight: '0',
      imagesbox: [
        { id: 0, url: require('../img/普通充电桩2.jpg') },
        { id: 1, url: require('../img/高压液冷超充.png') },
        { id: 2, url: require('../img/户用储能2.jpg') },
        { id: 3, url: require('../img/电力储能2.jpg') }
      ]
    }
  },
  methods: {
    imgLoad() {
      this.$nextTick(() => {
        this.imgHeight = window.innerHeight
      })
    },
    async getIMG(){
      let params={
        menuId:"2056"
      }
      const res = await getContents(params)
      const zj=JSON.parse(res[0].content)
      this.imagesbox= zj.imagesbox
    }
  },
  mounted() {
    this.getIMG()
    this.imgLoad()
    window.addEventListener('resize', this.imgLoad, false)
    // window.addEventListener("resize", () => {
    //   const res = document.querySelectorAll('#app')
    //   console.log(res,'res');
    //       console.log(document.body.clientWidth,'document.body.clientWidth');
    //   });
  },
  destroyed() {
    window.removeEventListener('resize', this.imgLoad, false)
  }
}
</script>

<style scoped>
::v-deep .el-carousel__container {
    position: relative;
    height: 100%;
}
.block {
  height: 100%;
  width: 100%;
}
.block img {
  height: 100%;
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .block{
  /* height: 100%; */
}
.block img {
  height: 210px;
  /* width: 100%; */
}
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>