<template>
  <div class="divCSs">
    <div class="beijing">ABOUT US</div>
    <div class="logoCss">
      <img src="../img/关于我们logo.png" alt="" />
    </div>
    <div class="titleCss"><span>About us</span></div>
    <div class="textCss">
      <p>
        {{this.ruleForm.jieshao}}
      </p>
    </div>

    <div class="ywfgCss">
      <!-- 左侧 -->
      <div class="ywfgLeft">
        <div class="wzLeft">
          <div class="leftblue"></div>
          <div class="ywtitleCss">
            Business coverage
            <p
              style="
                font-size: 18px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #8d8b8b;
              "
            >
              Business coverage
            </p>
          </div>
        </div>
        <div class="wenzhang">
          {{ this.ruleForm.yewu }}
        </div>
        <button @click="go" class="buttonljgd">Learn more<div class="imgCCC"><img style="margin-left:12px;" src="../img/了解更多1.png" alt=""></img></div></button>
      </div>
      <!-- 右侧 -->
      <div class="ywfgRight">
        
        <div  class="imgCss">
          <transition name="el-zoom-in-center">
          <img v-if="show" style="width: 59%" :src="this.fileListGYWM[0].url" alt="" />
        </transition>
        <transition name="el-zoom-in-center">
          <img v-if="show"
            @click="qiehuan"
            style="width: 59%; margin-left: 3%; opacity: 0.4;cursor: pointer;"
            :src="this.fileListGYWM[1].url"
            alt=""
          />
        </transition>
        <transition name="el-zoom-in-center">
          <img v-if="!show" style="width: 59%" :src="this.fileListGYWM[1].url" alt="" />
        </transition>
          <transition name="el-zoom-in-center">
          <img v-if="!show"
            @click="qiehuan"
            style="width: 59%; margin-left: 3%; opacity: 0.4;cursor: pointer;"
            :src="this.fileListGYWM[0].url"
            alt=""
          />
        </transition>
        </div>
      
      </div>
    </div>
  </div>
</template>

<script>
import {getContents} from "@/api/index.js";
export default {
  data() {
    return {
      fileListGYWM:[],
      ruleForm:{},
      show: true,
    };
  },
  methods: {
    async getData(){
      let params = {
        menuId:'2065'
      }
      const res = await getContents(params)
      console.log(res,'res');
      const zj=JSON.parse(res[0].content)
      this.ruleForm= zj.dataList[0]
      this.fileListGYWM= zj.dataList[0].imagesboxGYWM
      console.log(zj,'zj');
      console.log(this.fileListGYWM,'this.fileListGYWM');
    },
    go(){
      this.$router.push("/EnContactUs")
    },
    qiehuan() {
      console.log(this.show, "this.show");
      if (this.show == true) {
        this.show = false;
      } else {
        this.show = true;
      }
    },
  },
  mounted(){
    this.getData()
  }
};
</script>

<style scoped>
.beijing {
position: absolute;
white-space: nowrap;
z-index: 2;
top: 20.6%;
left: 31.2%;
opacity: 0.1;
font-size: 135px;
font-family: Microsoft YaHei-Bold, Microsoft YaHei;
font-weight: bold;
color: #777373;
line-height: 0px;
}
.el-zoom-in-center-enter,
.el-zoom-in-center-leave-to {
  opacity: 0;
}
/* 左侧文字 */
.ywfgCss {
  margin-top: 88px;
  margin-left: 12%;
  display: flex;
}
.ywfgLeft {
  display: flex;
  flex-direction: column;
  width: 47%;
}
.leftblue {
  /* position: absolute; */
  margin-left: 7%;
  margin-right: 1%;
  color: #0940fd;
  content: "";
  /* float: right; */
  border-left: 5px solid #0940fd;
  height: 48px;
}
.ywtitleCss {
  margin-left: 12px;
  font-size: 22px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #000000;
}
.wzLeft {
  display: flex;
  width: 47%;
}
.wenzhang {
  padding: 3%;
  margin-top: 22px;
  width: 100%;
  height: 237px;
  background: #f6fafd;
  font-size: 18px;
  line-height: 38px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #63798d;
  text-indent: 2em;
}
.buttonljgd {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -5%;
  margin-left: 3%;
  width: 225px;
  height: 75px;
  background: #e5ebff;
  border-radius: 12px 12px 12px 12px;
  border: none;
  font-size: 23px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #0940fd;
}
.buttonljgd:hover {
  background: #0940FD;
  color: #FFFFFF;
  .imgCCC img{
    content: url("../img/了解更多2.png");
  }
}

/* 右侧图片 */
.ywfgRight {
  width: 60%;
}
.imgCss {
  display: flex;
}

@media screen and (max-width: 720px) {
  .divCSs {
    display: none;
    position: relative;
  }
}
.divCSs {
  width: 100%;
  position: relative;
}
.titleCss {
  margin-bottom: 2.2%;
  text-align: center;
  font-size: 45px;
  font-family: Alimama ShuHeiTi-Bold, Alimama ShuHeiTi;
  font-weight: bold;
  color: #000000;
  position: relative;
  z-index: 9;
}
.textCss {
  /* margin-top: 5%; */
  margin-left: 12%;
  margin-right: 12%;
  text-indent: 2em;
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #636161;
  line-height: 2em;
  position: relative;
  z-index: 9;
}

.logoCss {
  text-align: center;
  margin-top: 119px;
  margin-bottom: 1%;
}
</style>