<template>
  <div>
    <HuanDengPian></HuanDengPian>
    <introduction></introduction>
    <chanpinleixing></chanpinleixing>
    <JieJueFangAn ></JieJueFangAn>
    <xiangguanzhixun></xiangguanzhixun>
  </div>
</template>

<script>
import HuanDengPian from './components/List'
import introduction from './components/introduction'
import chanpinleixing from './components/chanpinleixing'
import JieJueFangAn from './components/JieJueFangAn'
import xiangguanzhixun from './components/xiangguanzhixun'
export default {
  components: {
    HuanDengPian,
    chanpinleixing,
    introduction,
    JieJueFangAn,
    xiangguanzhixun,
  },
  created(){
    console.log(document);
    console.log(document.title);
    // document.title="武汉烽火富华电气有限责任公司"
  }
}
</script>
<style>
</style>