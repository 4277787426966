<template>
  <div class="divCSs">
    <div class="beijing">RELATED INFORMATION</div>
    <div class="logoCss">
      <img src="../img/相关资讯.png" alt="" />
    </div>
    <div class="titleCss"><span>Relevant information</span></div>
    <div class="textCss">
      <div class="bigCSS" v-for="(item,index) in this.dataList.slice(0, 3)" :key="index" @click="go(item)">
        <div class="boxCSS" >
          <img style="width: 100%;height: 284px;border-radius:16px" :src="item.content.dataList[0].url" alt="" />
          <div class="boxTitle">{{item.content.dataList[0].name}}</div>
          <div class="boxdata">
            {{item.content.dataList[0].jieshao}}
          </div>
          <div class="timecss">{{item.content.dataList[0].time}}<img style="float: right;" src="../img/资讯右2.png" alt=""></div>
        </div>
      </div>
    </div>
    <button @click="goxw" class="ckxz">more</button>
  </div>
</template>

<script>
import {
  uploadFile,
  getContents,
  getMenus,
  upContent,
  getProSecLevelType,
  getProRot,
} from "@/api/index.js";
export default {
  data() {
    return {
      dataList:[
      {title:"首次“新能源产业及应用”专题援外培训顺利完成",data:"由中华人民共和国商务部主办，中国信科集团承办的发展中国家援外培训班于今年6月顺利启动。近日，“物联网技术及应用研修班”、“移动互联网发展研修班”和“下一代通信网络建设研修班”陆续在汉举行隆重的开班典礼。本次研修班，学员分别来自阿塞拜疆、老挝、蒙古、伊拉克、乌兹别克斯坦、亚美尼亚、尼泊尔、埃及、突尼斯、加纳、尼日利亚、苏里南、摩洛哥、莱索托、牙买加、古巴、萨摩亚共18个发展中国家的内政部、通信部、科技部、电信局、国家电视台、高校和相关企业，含4位司局级、37位处级和多位高级管理和技术人员。",url: require("@/views/News/img/援外.jpg"),time:'2023/06/20', router: "/index230620"},
      {title:"烽火富华荣获“2023中国充换电行业十大竞争力品牌”",data:"6月7日，由中国充电桩网、充换电百人会主办的“2023上海国际充电桩及换电技术设备展览会（简称CPSE）”在上海汽车会展中心盛大开展。超2.5万平米展馆、超500家展商、超2万人以上专业观众共同组成了声势浩大的展会阵容。在展会同期举办的“2023中国充换电行业十大品牌评选活动”上，烽火富华荣获“2023中国充换电行业十大竞争力品牌”。",url: require("@/views/News/img/喜报.jpg"),time:'2023/06/09', router: "/index230609"},
      {title:"武汉烽火富华电气有限责任公司第某次某某会议",data:"武汉烽火富华电气有限责任公司（以下简称“公司”）第六届董事会第一次会议于2023年3月2日某某某某某某某",url: require("@/views/News/img/六董会.jpg"),time:'2023/04/28'},
      ]
    };
  },
  methods: {
    async getList() {
      let params = {
        menuId: "2055",
        id: "",
      };
      const res = await getContents(params);
      for (let index = 0; index < res.length; index++) {
        // const zj = JSON.parse(res[0].content);
        res[index].content = JSON.parse(res[index].content);
      }
      // const zj = JSON.parse(res[0].content);
      console.log(res, "ress");
      this.dataList = res;
    },
    //路由跳转事件
    go(index) {
      // this.$router.push(index.router);
      this.$router.push("/EnNews");
    },
    goxw() {
      this.$router.push("/EnNews");
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style scoped>
.ckxz {
  margin-bottom: 5%;
    margin-top: 2%;
    margin-right: 44%;
    margin-left: 44%;
  width: 225px;
  height: 75px;
  background: #e5ebff;
  border-radius: 12px 12px 12px 12px;
  border: none;
  font-size: 23px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #0940fd;
}
.ckxz:hover {
  background: #0940FD;
  color: #FFFFFF;
}
.timecss{
  font-size: 18px;
font-family: Microsoft YaHei-Regular, Microsoft YaHei;
font-weight: 400;
color: #747272;
  margin-top: 8%;
    margin-bottom: 4%;
}
.boxdata {
  font-size: 20px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #747272;
  line-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin-top: 5%;
}
.boxTitle {
  font-size: 26px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  line-height: 32px;
  color: #000000;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  margin-top: 5%;
}
.bigCSS {
  margin-left: 1%;
  margin-right: 1%;
  width: 31.5%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.08);
  border-radius: 24px 24px 24px 24px;
}
.bigCSS:hover{
  background: #0940FD;
box-shadow: 0px 0px 30px 1px rgba(9,64,253,0.6);
border-radius: 24px 24px 24px 24px;
 .timecss img{
  content: url("../img/资讯右.png");
 }
}
.bigCSS:hover .boxTitle{
  font-size: 26px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  line-height: 32px;
  color: #ffffff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  margin-top: 5%;
}
.bigCSS:hover .boxdata {
  font-size: 20px;
font-family: Microsoft YaHei-Regular, Microsoft YaHei;
font-weight: 400;
color: #dfd9d9;
line-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin-top: 5%;
}
.bigCSS:hover .timecss{
  font-size: 18px;
font-family: Microsoft YaHei-Regular, Microsoft YaHei;
font-weight: 400;
color: #dfd9d9;
  margin-top: 8%;
    margin-bottom: 4%;
}
.boxCSS {
  height: 100%;
  /* background: #c93c3c; */
  padding: 24px;
  cursor: pointer;
}
.beijing {
  position: absolute;
  white-space: nowrap;
  z-index: 2;
  top: 13%;
  left: 6%;
  right: 6%;
  opacity: 0.1;
  font-size: 135px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #777373;
  line-height: 0px;
}
.divCSs {
  width: 100%;
  position: relative;
}
.titleCss {
  margin-bottom: 2.2%;
  text-align: center;
  font-size: 45px;
  font-family: Alimama ShuHeiTi-Bold, Alimama ShuHeiTi;
  font-weight: bold;
  color: #000000;
  position: relative;
  z-index: 9;
}
.textCss {
  display: flex;
  justify-content: center;
  /* background: #000; */
  margin-left: 10%;
  margin-right: 10%;
  position: relative;
  z-index: 9;
}

.logoCss {
  text-align: center;
  margin-top: 119px;
  margin-bottom: 1%;
}
</style>